/*
 * @Author: huanmeng122@aliyun.com huanmeng122@aliyun.com
 * @Date: 2022-08-13 08:38:58
 * @LastEditors: huanmeng122@aliyun.com huanmeng122@aliyun.com
 * @LastEditTime: 2025-03-12 09:44:41
 * @FilePath: \ding-lims\src\common\fileUpload.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 上传oss
// import common from '@/common/common.js'
import { get, post } from '@/api/https'
export function ossUpload(fileBuffer) {
  return new Promise((resolve, reject) => {
    const files = fileBuffer.file
    let formData = new FormData()
    // const foldName = common.formatDate((new Date()).valueOf() * 1000)
    const type = files.name.slice(files.name.lastIndexOf('.') + 1)
    const uid = files.lastModified + Math.floor(Math.random()*100)
    formData.append('files', files)
    formData.append('path', 'upload/' + uid + '.' + type)
    post('user/upload-oss', 'api', formData, {
      transformRequest: [
        function(data, headers) {
          // 去除post请求默认的Content-Type
          delete headers['Content-Type']
          return data
        },
      ],
    }).then(res => {
      if (+res.code === 0) {
        const resultData = {
          uid: uid,
          name: formData.get('path'),
          url: res.data.signedUrl,
          type: files.type
        }
        resolve(resultData)
        return resultData
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取oss地址
export function showPrivateOss (picName, typeName) {
  console.log('picName', picName)
  console.log('typeName', typeName)
  return new Promise((resolve, reject) => {
    if (typeName === 'oss') {
      resolve(picName)
    } else {
      get('user/get-signed-url', 'api', {name: picName}).then(res => {
        if (+res.code === 0) {
          resolve(res.data.signedUrl)
        } else {
          reject(new Error('Failed to get signed URL'))
        }
      }).catch(err => {
        reject(err)
      })
    }
  })
}